import React from 'react';
import DefaultLayout from './containers/DefaultLayout';
import LoadingCircular from './components/Loading/LoadingCircular';
import Loadable from 'react-loadable';

function Loading() {
  return <LoadingCircular />;
}

const Commision = Loadable({
  loader: () => import('./views/Commision/Commision'),
  loading: Loading,
});

const Coupon = Loadable({
  loader: () => import('./views/Coupon/Coupon'),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import('./views/ResetPasswordSetting/ResetPassword'),
  loading: Loading,
});

export interface RouteItem {
  path: string;
  exact?: Boolean;
  name: string;
  component: any;
}

const routeItems: RouteItem[] = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  {
    path: '/commision',
    name: 'Commision',
    exact: true,
    component: Commision,
  },
  {
    path: '/coupon',
    name: 'Coupon',
    exact: true,
    component: Coupon,
  },
  {
    path: '/settings/reset-password',
    name: 'Reset Password',
    exact: true,
    component: ResetPassword,
  },
];

export default routeItems;
