const development =  {
  // baseURL: "http://192.168.100.81:5000/affiliate"
  baseURL: "https://api-dev.i-dealhomeonline.com/affiliate"
}
const production =  {
  baseURL: "https://api.i-dealhomeonline.com/affiliate"
}

const hostname = window && window.location && window.location.hostname;
let environments = development;

if (
  hostname &&
  (hostname.includes('https://staging-teman.i-dealhomeonline.com') ||
  hostname.includes('staging-teman.netlify.com'))
  ) {
    environments = development;
  }
  else if (
    hostname &&
    (hostname.includes('https://teman.i-dealhomeonline.com') ||
    hostname.includes('temanideal.netlify.com'))
  ) {
    environments = production;
  }
    
export default environments;
