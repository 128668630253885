export default {
  items: [
    {
      name: "Commision",
      url: "/commision",
      icon: "fa fa-dollar"
    },
    {
      name: "Coupon",
      url: "/coupon",
      icon: "fa fa-ticket"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-user",
      children: [
        {
          name: "Reset Password",
          url: "/settings/reset-password",
          icon: "fa fa-user"
        },
      ],
    },
  ]
};
