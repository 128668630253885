import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authDispatcher, authSelector } from '../models/auth';
import Toast from '../helpers/toast';
import LoadingCircular from '../components/Loading/LoadingCircular';

interface Props {
  isAuthenticated: boolean;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Login(props: Props) {
  const dispatch = useDispatch();
  const dispatchLogin = authDispatcher(dispatch).login;
  const error = useSelector(authSelector.errorSelector);
  const loading = useSelector(authSelector.isLoggingInSelector);

  useEffect(() => {
    if (!!error) {
      Toast.error(error.message);
    }
  }, [error]);

  const [form, setForm] = useState({ username: '', password: '' });
  const { username, password } = form;

  const onChange = useCallback(
    e => {
      let name = e.target.name;
      let value = e.target.value;

      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }));
    },
    [setForm],
  );

  const login = useCallback(() => {
    dispatchLogin(username, password);
  }, [dispatchLogin, username, password]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row>
          <Col md="12">
            <div className="logo-login"></div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-0">
                <CardBody>
                  <Form className="style-label">
                    <h1 className="text-center mb-4">Login</h1>
                    <Label htmlFor="username">Username</Label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onKeyPress={handleKeyPress}
                        type="text"
                        name="username"
                        value={username}
                        placeholder="Username"
                        autoComplete="username"
                        onChange={onChange}
                      />
                    </InputGroup>
                    <Label htmlFor="password">Password</Label>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onKeyPress={handleKeyPress}
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={onChange}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="12" className="text-center">
                        {loading ? (
                          <LoadingCircular />
                        ) : (
                          <Button
                            color="primary"
                            onClick={() => login()}
                            className="px-4 btn-green"
                          >
                            Login
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
