import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react';
import logo from '../../assets/img/brand/logo-ideal.png';
import sygnet from '../../assets/img/brand/logo-ideal.png';
import { useDispatch, useSelector } from 'react-redux';
import { authDispatcher, authSelector } from 'models/auth';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

let DefaultHeader = function() {
  const dispatch = useDispatch();
  const { logout } = authDispatcher(dispatch);
  const name = useSelector(authSelector.userSelector);

  let username = name ? name.username : 'admin@admin.com';

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppSidebarToggler className="d-md-down-none" display="lg" />
      <AppNavbarBrand
        full={{ src: logo, width: 110, height: 35, alt: 'IDeal Logo' }}
        minimized={{ src: sygnet, width: 80, height: 30, alt: 'IDeal Logo' }}
      />

      <Nav className="ml-auto" navbar>
        <AppHeaderDropdown className="sm-min-width-0" direction="down">
          <DropdownToggle nav className="text-right d-md-flex">
            <label className="d-sm-down-none m-md-auto cursor-point fc-white">
              {username}&nbsp;
            </label>
            <span className="fa fa-user btn-icon-user"></span>
          </DropdownToggle>
          <DropdownMenu>
            {/* <DropdownItem>
              <i className="fa fa-user"></i> Profile
            </DropdownItem> */}
            <DropdownItem onClick={logout}>
              <i className="fa fa-lock"></i> Logout
            </DropdownItem>
          </DropdownMenu> 
        </AppHeaderDropdown>
      </Nav>
    </React.Fragment>
  );
};

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
